"use client"
import Link from "next/link"
import { useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import { branding, routes } from "../../api/meta"
import ProfileComponent from "./ProfileUIComponent"
import { RiMenu2Fill } from "react-icons/ri"
import { Sheet, SheetTrigger } from "@/components/ui/sheet"
import { getInfoWithSessionTicket } from "@/app/auth/login/authAction"
import { useAppDispatch, useAppSelector } from "@/app/redux/hooks/reduxHooks"
import SheetComponent from "./SheetComponent"
import Image from "next/image"
import { excluder } from "@/lib/renderHeaderFooter"
import { HeaderCurrencyComponent } from "./HeaderCurrencyComponent"
import { motion } from "framer-motion"
import { toast } from "react-toastify"
import { useSearchParams } from "next/navigation"

type NavbarProps = {
    sessionTicket: string | undefined
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const { sessionTicket } = props
    const dispatch = useAppDispatch()
    const auth = useAppSelector((state) => state.auth)
    const isUserSignedIn = useAppSelector((state) => state.auth.isUserSignedIn)
    const searchParams = useSearchParams()
    const querySessionTicket = searchParams.get("sessionTicket")

    useEffect(() => {
        if (sessionTicket && !querySessionTicket) {
            fetchUserInfo(sessionTicket)
        } else if (!sessionTicket && !querySessionTicket) {
            dispatch({ type: "auth/signOut" })
        }
    }, [sessionTicket])

    const fetchUserInfo = async (sessionTicket: string) => {
        try {
            const response = await getInfoWithSessionTicket(sessionTicket)
            if (response && response.solana && response.solana.data.result.data.Data.gameshiftAccountInfo) {
                const parsed = JSON.parse(response.solana.data.result.data.Data.gameshiftAccountInfo.Value)
                const payload = {
                    solanaWallet: parsed.externalWallet,
                    solanaWalletType: parsed.externalWalletType,
                }
                dispatch({ type: "auth/setSolanaWallet", payload })
            }
            dispatch({ type: "auth/signIn", payload: response })
        } catch (error: any) {
            console.error("Error fetching user info:", error)
            toast.error(`Sorry, there was an error logging you in, please try again`)
        }
    }

    process.env.NEXT_PUBLIC_ENV === "development" && console.log("auth: ", auth)
    const { exclude } = excluder()

    return (
        <Sheet>
            {exclude ? null : (
                <>
                    <header>
                        <div className="bg-secondary border-b border-[#303030] py-6">
                            <link rel="icon" href="/favicon.ico" sizes="any" />
                            <div className="mx-auto max-w-[1200px] px-6">
                                <nav className="flex h-3 sm:h-16 items-center justify-between">
                                    <SheetTrigger asChild>
                                        <div className="w-[70px]">
                                            <RiMenu2Fill className="hover:text-primary text-white hover:scale-110 transition-all cursor-pointer" />
                                        </div>
                                    </SheetTrigger>
                                    <div className="flex flex-1">
                                        <div className="flex flex-shrink-0 items-center">
                                            <Link href={isUserSignedIn ? "/dashboard" : "/"}>
                                                <Image
                                                    width="120"
                                                    height="32"
                                                    src={branding.mainLogo.path}
                                                    alt={branding.mainLogo.alt}
                                                />
                                            </Link>
                                            <div className="hidden md:ml-6 lg:block">
                                                <div className="flex space-x-4 gap-6">
                                                    {routes.map((item, index) => (
                                                        <Link
                                                            key={item.title}
                                                            href={item.path}
                                                            className="text-white transition-all hover:text-primary"
                                                        >
                                                            <small className="relative right-1 bottom-1 text-[10px]">
                                                                00{index + 1}
                                                            </small>
                                                            {item.title}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:flex justify-end lg:justify-center">
                                        <ProfileComponent />
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {isUserSignedIn && (
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0, transition: { duration: 1.5 } }}
                            >
                                <HeaderCurrencyComponent auth={auth} />
                            </motion.div>
                        )}
                    </header>
                    {/* <section className="p-3 flex items-center justify-center text-white">
                        <div>Get $MCG to empower your gameplay</div>
                        <div>
                            <Link
                                href="https://onramp.gatefi.com/?merchantId=337beb4b-6be3-4c6e-bc8c-6c8a32be8f1f?crypto=MCG_SOL"
                                target="_blank"
                                className="bg-primary ml-3 px-2 font-secondary py-1 rounded-md text-black mt-2 min-w-[125px] hover:bg-primary-light disabled:bg-gray-500 disabled:text-gray-200 disabled:cursor-not-allowed"
                            >
                                GET MCG
                            </Link>
                        </div>
                    </section> */}
                </>
            )}
            <ToastContainer
                position="bottom-right"
                autoClose={8000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <SheetComponent />
        </Sheet>
    )
}

export default Navbar
